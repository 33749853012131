$primary-color: #ed1c24;

.table-card-container {
    padding-left: 0;
    padding-right: 0;
}

.cabecera {
    display: block;

    &-icon {
        width: 100%;
        text-align: center;
    }
}

.table-concesionarios {
    margin-left: 20px;
    margin-right: 20px;

    thead {
        background-color: $primary-color;

        th {
            color: white;
        }
    }
}

.card {
    &-header {
        border-bottom: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

#button_filter__collapse {
    span {
        font-weight: bold;
        padding-right: 8px;
        position: relative;
        bottom: 5px;
    }
}

.card-body-filtros {
    padding-top: 0;
    padding-bottom: 15px;
    padding-left: 0;
}

.detalle-leads {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
}

.buttons {
    margin-bottom: 20px;

    button {
        transform: scale(0.95);
    }
}

.table-leads {
    thead {
        background-color: $primary-color;

        tr {
            th {
                color: white;
                vertical-align: middle;
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            th {
                vertical-align: middle;
                text-align: center;

                .badge {
                    width: 100%;
                }
            }
        }
    }

}

.content-to-edit {
    padding-top: 0;
}

.form-label {
    font-weight: bold;
}


.td-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .td-mobile {
        display: block;
    }

    .table-responsive {
        overflow-x: hidden;
    }

    table {
        text-align: center;
        overflow-x: none;

        thead {
            display: none;
        }

        tbody {
            .tr-mobile {
                display: block;
                margin-bottom: 25px;
                padding-top: 15px;
                padding-bottom: 15px;

                td {
                    display: block;
                    width: 100%;
                    margin-bottom: 10px;
                    padding-top: 5px;
                    padding-bottom: 15px;

                    .badge {
                        width: auto;
                        max-width: 50%;
                        margin: 0 auto;
                        padding: 5px 10px;
                        display: block;
                    }
                }
            }
        }
    }
}